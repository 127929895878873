import { Button, Col, Modal, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { IEntityMedia, IFeedProduct, IFeedProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/useProductFeed';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { brandName } from 'theme/libs/config';
import { categoryUrl } from 'theme/libs/routes';
import { first } from 'ts-functional';
import { MediaBugs } from '../MediaBugs';
import { MediaSwitcher } from '../MediaSwitcher';
import { ProductAccessories } from '../ProductAccessories';
import { ProductBullets } from '../ProductBullets';
import { ProductOptions } from '../ProductOptions';
import { ProductPrice } from '../ProductPrice';
import { ProductRelatedProducts } from '../ProductRelatedProducts';
import { ProductSizing } from '../ProductSizing';
import { ProductTitle } from '../ProductTitle';
import './Product.styles.less';
import { IProductProps } from "./Product.types";
import { OnlineDealers } from '../OnlineDealers';

export const Product = (props: IProductProps) => {
    const [data, loading] = useProductGroup(+props.productGroupId);
    const [isOnlineDealersVisible, setIsOnlineDealersVisible] = React.useState(false);

    const product:IFeedProduct | undefined = !!data?.products ? data.products[0] : undefined;
    const productGroup:IFeedProductGroup | undefined = !!product && !!product.productGroups
        ? first(product.productGroups.filter(g => g.productGroupId === +props.productGroupId))
        : undefined;

    const relatedProducts  = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag === ""                          ) : [];
    // const accessories      = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag.indexOf("ACCESSORIES"     ) >= 0) : [];
    // const replacementParts = !!productGroup && productGroup.relatedEntity ? productGroup.relatedEntity.filter(pg => pg.tag.indexOf("REPLACEMENT_PART") >= 0) : [];
    const accessoryImage   = !!product && product.media ? product.media.filter(media => media.tag.toLowerCase() === "accessories"                          ) : [];
    const replacementPartImage  = !!product && product.media ? product.media.filter(media => media.tag.toLowerCase() === "replacementpart"                 ) : [];
    const accessoryLink    = !!product && product.metadata ? product.metadata.filter(meta => meta.title === "accessory-link"                               )[0]?.body : '';
    const replacementPartLink = !!product && product.metadata ? product.metadata.filter(meta => meta.title === "replacementparts-link"                     )[0]?.body : '';
    const accessoryCopyBlock  = !!product && product.metadata ? product.metadata.filter(meta => meta.title.toLowerCase() === "accessories"                 )[0]?.body : '';
    const replacementPartCopyBlock = !!product && product.metadata ? product.metadata.filter(meta => meta.title.toLowerCase() === "replacementparts"       )[0]?.body : '';
    const accessoryBtnLabel  = !!product && product.metadata ? product.metadata.filter(meta => meta.title.toLowerCase() === "accessory-button-label"       )[0]?.body : '';
    const replacementPartBtnLabel = !!product && product.metadata ? product.metadata.filter(meta => meta.title.toLowerCase() === "parts-button-label"      )[0]?.body : '';

    const allMedia:IEntityMedia[] = [
        productGroup,
        ...(!!productGroup && productGroup.media ? productGroup.media : [])
    ] as IEntityMedia[];

    const getVideoId = (vid?:IEntityMedia) => !!vid ? vid.mediaUrl.replace("youtube-", "") : "";

    const showOnlineDealers = () => setIsOnlineDealersVisible(true);
    const onWebDelearModalClose = () => setIsOnlineDealersVisible(false);

    return <Page
        className="Product"
        title={`${brandName} - Product`}>
        {loading && <Spinner defaultIndicator={true} size="large" />}
        {!loading && !!product && !!productGroup && 
        <>
        {!!allMedia && allMedia.filter((media:IEntityMedia) => media.mediaTypeDescription === "video" && media.tag.toLowerCase() !== 'threesixty' && media.tag.toLowerCase() === 'bannervideo').map((vid, i) => {
            const videoId = getVideoId(vid);
            return (
                <>
                <div className='videoContainer bannervideo'>
                    <iframe
                        allowFullScreen
                        key={`bannervideo-${i}`}
                        className="video"
                        frameBorder={0}
                        src={`https://www.youtube-nocookie.com/embed/${videoId}?wmode=transparent&html5=1&autoplay=1&rel=0&mute=1&controls=0&playlist=${videoId}&loop=1`}
                        style={{width: "100%"}}
                    />
                </div>
                </>
            )
        })}
        <div className="products">
            <Modal className={"onlineDealerModal"} centered destroyOnClose width={'100%'} footer={false} visible={isOnlineDealersVisible} onCancel={onWebDelearModalClose}>
                <OnlineDealers />
            </Modal>
            {/* {loading && <Spinner defaultIndicator={true} size="large" />}
            {!loading && !!product && !!productGroup &&  */}
            <div className="product section">
                <Row gutter={[16, 16]} className="section">
                    {/* Desktop devices */}
                    <Col md={12} xs={0}>
                        <div className="breadcrumbs">
                            {!!productGroup.breadcrumb && productGroup.breadcrumb.map(crumb =>
                                <span key={crumb.categoryId} className="breadcrumb">
                                    {![15316].includes(crumb.categoryId) // Helmets category should not be clickable
                                        ? <Link to={categoryUrl(crumb.categoryId)}>{crumb.categoryName}</Link>
                                        : <>{crumb.categoryName}</>
                                    }
                                </span>
                            )}
                        </div>
                        <ProductTitle title={productGroup.productGroupName} caption={productGroup.caption} />
                        <ProductBullets
                            bullets={
                                productGroup.features
                                    ? productGroup.features.filter(feature => feature.featureType === 'bullet')
                                    : []
                            }
                        />
                        <ProductPrice priceRange={productGroup.retailPriceDisplayRange} />
                        <div className='shopButtons'>
                            <Button><a href={productGroup && productGroup.googleShoppingUrl} target="_blank">Shop Online</a></Button> &nbsp;
                            <Button><a href="/dealer-search">Shop Local</a></Button> &nbsp; 
                            <Button onClick={showOnlineDealers}>Online Dealers</Button>
                        </div>
                        <MediaBugs media={allMedia} />
                    </Col>
                    <Col md={12} xs={0}>
                        {/* <MediaSwitcher media={allMedia} thumbnailUrl='/img/video_icon.png'/> */}
                        <MediaSwitcher media={allMedia.filter((media:IEntityMedia) => media.mediaTypeDescription === "image" || media.tag.toLowerCase() === 'threesixty')} />
                    </Col>

                    {/* FOR MOBILE DEVICES*/}
                    <Col md={0} xs={24}>
                        <div className="breadcrumbs">
                            {!!productGroup.breadcrumb && productGroup.breadcrumb.map(crumb =>
                                <span key={crumb.categoryId} className="breadcrumb">
                                    {![15316].includes(crumb.categoryId) // Helmets category should not be clickable
                                        ? <Link to={categoryUrl(crumb.categoryId)}>{crumb.categoryName}</Link>
                                        : <>{crumb.categoryName}</>
                                    }
                                </span>
                            )}
                        </div>
                        <ProductTitle title={productGroup.productGroupName} caption={productGroup.caption} />
                    </Col>
                    <Col md={0} xs={24}>
                        <MediaSwitcher media={allMedia.filter((media:IEntityMedia) => media.mediaTypeDescription === "image" || media.tag.toLowerCase() === 'threesixty')} />
                    </Col>
                    <Col md={0} xs={24}>
                        <ProductBullets
                            bullets={
                                productGroup.features
                                    ? productGroup.features.filter(feature => feature.featureType === 'bullet')
                                    : []
                            }
                        />
                        <ProductPrice priceRange={productGroup.retailPriceDisplayRange} />
                        <div className='shopButtons'>
                            <Button><a href={productGroup && productGroup.googleShoppingUrl} target="_blank">Shop Online</a></Button> &nbsp;
                            <Button><a href="/dealer-search">Shop Local</a></Button> &nbsp; 
                            <Button onClick={showOnlineDealers}>Online Dealers</Button>
                        </div>
                        <MediaBugs media={allMedia} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                { product.productGroups && product.productGroups.length > 1 &&
                                    <div className="section">
                                        <ProductOptions
                                            alignItems={"left"}
                                            productGroups={product.productGroups}
                                            title={"Colorways"}
                                        />
                                    </div>
                                }
                                { !!productGroup.chart && productGroup.chart[0] && productGroup.chart[0].header[0] && productGroup.chart[0].header[0].columns &&
                                    <div className="section">
                                        <ProductSizing chart={productGroup.chart[0]} />
                                    </div>
                                }
                                {!!allMedia && allMedia.filter((media:IEntityMedia) => media.mediaTypeDescription === "video" && media.tag.toLowerCase() !== 'threesixty' && media.tag.toLowerCase() !== 'bannervideo').map((vid, i) => {
                                    const videoId = getVideoId(vid);
                                    return (
                                        <>
                                        <div className='videoContainer'>
                                            <iframe
                                                allowFullScreen
                                                key={`video-${i}`}
                                                className="video"
                                                frameBorder={0}
                                                src={`https://www.youtube-nocookie.com/embed/${videoId}?wmode=transparent&html5=1&autoplay=0&rel=0`}
                                                style={{width: "100%"}}
                                            />
                                        </div>
                                        </>
                                    )
                                })}
                                { relatedProducts.length > 0 &&
                                    <div className="section">
                                        <ProductRelatedProducts
                                            relatedProducts={relatedProducts}
                                            title={"Related Products"}
                                        />
                                    </div>
                                }
                                {
                                    !!product.metadata && typeof accessoryImage !== 'undefined' && accessoryImage.length > 0 && accessoryLink !== undefined && accessoryCopyBlock !== undefined && 
                                        <div className="section prodAccessories">
                                            <ProductAccessories link={accessoryLink} copyBlock={accessoryCopyBlock} image={accessoryImage[0]} buttonText={accessoryBtnLabel !== undefined ? accessoryBtnLabel : "VIEW SHIELDS"} />
                                        </div>
                                }
                                {
                                    !!product.metadata && typeof replacementPartImage !== 'undefined' && replacementPartImage.length > 0 && replacementPartLink !== undefined && replacementPartCopyBlock !== undefined &&
                                        <div className="section prodAccessories">
                                            <ProductAccessories link={replacementPartLink} copyBlock={replacementPartCopyBlock} image={replacementPartImage[0]} buttonText={replacementPartBtnLabel !== undefined ? replacementPartBtnLabel : "VIEW PARTS"} displayReverse={typeof accessoryImage === 'undefined' || accessoryImage.length === 0 ? false : true}/>
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        </>}
    </Page>
}